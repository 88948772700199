<template>
    <div class="row">
      <div class="card-deck">
        <card>
          <template v-slot:image>
          <img
            class="card-img-top"
            src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
          />
          </template>
          <h5 class="card-title">{{getTrans('messages.camhead')}}</h5>
          <p class="card-text">{{getTrans('messages.camhead_desc')}}</p>
          <template v-slot:footer>
            <div  class="text-center">
              <base-button type="primary" @click="camhead">{{getTrans('messages.camhead')}}</base-button>
            </div>
          </template>
        </card>
        <card>
          <template v-slot:image>
          <img
            class="card-img-top"
            src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
          />
          </template>
          <h5 class="card-title">{{getTrans('messages.widget')}}</h5>
          <p class="card-text">{{getTrans('messages.widget_desc')}}</p>
          <template v-slot:footer>
            <div class="text-center">
              <base-button type="primary" @click="widget">{{getTrans('messages.widget')}}</base-button>
            </div>
          </template>
        </card>
        <card>
          <template v-slot:image>
          <img
            class="card-img-top"
            src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
          />
          </template>
          <h5 class="card-title">{{getTrans('messages.smartlink')}}</h5>
          <p class="card-text">{{getTrans('messages.smartlink_desc')}}</p>
          <template v-slot:footer>
            <div class="text-center">
              <base-button type="primary" @click="smartlink">{{getTrans('messages.smartlink')}}</base-button>
            </div>
          </template>
        </card>
        <card>
          <template v-slot:image>
          <img
            class="card-img-top"
            src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
            :alt="getTrans('messages.invite_link')"
          />
          </template>
          <h5 class="card-title">{{getTrans('messages.invite_friend')}}</h5>
          <p class="card-text">{{getTrans('messages.invite_link_desc')}}</p>
          <template v-slot:footer>
            <div class="text-center">
              <base-button type="primary" @click="inviteafriend">{{getTrans('messages.invite_link')}}</base-button>
            </div>
          </template>
        </card>
      </div>
    </div>
</template>
<script>
import Card from "@/components/Cards/Card.vue";
export default {
  components: {
    Card,
  },
  data() {
    return {};
  },
  methods: {
    camhead() {
      this.$router.push({ name: "CamHead" });
    },
    widget() {
      this.$router.push({ name: "Widget" });
    },
    smartlink() {
      this.$router.push({ name: "SmartLink" });
    },
    inviteafriend() {
      this.$router.push({ name: "invite_friend" });
    }
  }
};
</script>
<style></style>
